<template>
  <b-modal
    v-model="dialog"
    title="Attach Statement"
    no-close-on-backdrop
    ok-title="Submit"
    size="lg"
    :busy="submitLoader || loading"
    @hidden="$emit('modal-closed')"
    @ok="handleSubmit"
  >
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>
    <b-table-simple
      striped
      hover
      show-empty
      class="compact-table"
    >
      <b-tr>
        <b-th>Doc No</b-th>
        <b-td> {{ data.doc_no }} </b-td>
        <b-th>Date</b-th>
        <b-td> {{ formatedDate(data.date) }} </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Party
        </b-th>
        <b-td>
          {{ data.party__identifier }}
        </b-td>
        <b-th>
          Statement
        </b-th>
        <b-td>
          {{ selectedStatement || '-' }}
          <b-button
            v-if="selectedStatement"
            variant="outline-primary"
            size="sm"
            @click="selectedStatement = null"
          >
            Clear
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <b-alert
      variant="danger"
      :show="submitError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ submitError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading">
      <b-table
        :items="statements"
        :fields="tableFields"
        striped
        responsive
        hover
        caption-top
        show-empty
        class="compact-table"
        no-local-sorting
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="sortingChanged"
      >
        <template #table-caption>
          Select statement from below table:
        </template>
        <template #cell(action)="{item}">
          <b-form-radio
            v-model="selectedStatement"
            :value="item.id"
          />
        </template>
        <template #cell(start_date)="{item}">
          {{ formatedDate(item.start_date) }}
        </template>
        <template #cell(end_date)="{item}">
          {{ formatedDate(item.end_date) }}
        </template>
        <template #cell(due_date)="{item}">
          {{ formatedDate(item.due_date) }}
        </template>
        <template #cell(paid_amount)="{item}">
          {{ formateNumber(item.paid_amount) }}
        </template>
        <template #cell(total_amount)="{item}">
          {{ formateNumber(item.total_amount) }}
        </template>
        <template #cell(pay_status)="{item}">
          <status-icon
            v-if="item.pay_status"
            :value="item.pay_status"
          />
        </template>
      </b-table>

      <detailed-pagination
        v-if="!(loading || errorMessage)"
        :total-items="totalItems"
        :per-page="perPage"
        :current-page="currentPage"
        @page-changed="
          (page) => {
            currentPage = page;
          }
        "
      />
    </div>
  </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin'
import {
  BModal,
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BSpinner,
  BTable,
  BFormRadio,
  BAlert,
  BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import formatMixin from '@/mixins/formatMixin'
import DetailedPagination from '../UI/DetailedPagination.vue'
import StatusIcon from '../UI/StatusIcon.vue'

export default {
  components: {
    BModal,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BSpinner,
    DetailedPagination,
    BTable,
    StatusIcon,
    BFormRadio,
    BAlert,
    BButton,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedStatement: null,
      loading: false,
      errorMessage: null,
      submitLoader: false,
      submitError: null,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: 'due_date',
      sortDesc: true,
      statements: null,
      tableFields: [
        { key: 'action', label: '', sortable: true },
        { key: 'id', label: 'Id', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'due_date', label: 'Due Date', sortable: true },
        {
          key: 'total_amount',
          label: 'Total Amount',
          sortable: true,
          class: 'text-right pr-2',
        },
        { key: 'pay_status', label: 'Status', sortable: true },
        {
          key: 'paid_amount',
          label: 'Paid Amount',
          sortable: true,
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  watch: {
    currentPage() {
      this.fetchStatementsByParty()
    },
  },
  mounted() {
    this.fetchStatementsByParty()
    this.selectedStatement = this.$props.data.statement_id
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchStatementsByParty()
    },
    async fetchStatementsByParty() {
      try {
        this.loading = true
        this.errorMessage = null
        const { data } = await axios.post('/accounts/statements', {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
          filters: {
            parties: [this.$props.data.party],
          },
        })
        this.totalItems = data.count
        this.statements = data.results
      } catch (error) {
        this.errorMessage = error?.response?.data?.error || 'Something went wrong!'
      } finally {
        this.loading = false
      }
    },
    async handleSubmit(event) {
      event.preventDefault()
      try {
        this.submitLoader = true
        this.submitError = null
        await axios.patch(
          `/accounts/update_credit_debit_notes/${this.$props.data.id}`,
          {
            statement_id: this.selectedStatement || '',
          },
        )
        this.$emit('handleUpdate')
      } catch (error) {
        this.submitError = error?.response?.data?.error || 'Failed to attach statement!'
      } finally {
        this.submitLoader = false
      }
    },
  },
}
</script>
