<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>
    <b-table
      v-if="!(loading || errorMessage)"
      :items="notesData"
      :fields="tableFields"
      responsive
      striped
      hover
      show-empty
      class="compact-table"
      no-local-sorting
      :sort-by="filters.sortBy"
      :sort-desc="filters.sortDesc"
      @sort-changed="sortingChanged"
    >
      <template #cell(doc_no)="{item}">
        <span class="d-flex align-items-center">
          {{ item.doc_no }}
          <span
            v-if="item.is_modified"
            class="is-modified-bill"
          />
        </span>
      </template>
      <template #cell(date)="{item}">
        {{ formatedDate(item.date) }}
      </template>
      <template #cell(amount)="{item}">
        {{ formateNumber(item.amount) }}
      </template>
      <template #cell(due_amount)="{item}">
        {{ formateNumber(item.due_amount) }}
      </template>
      <template #cell(pay_status)="{item}">
        <status-icon :value="item.pay_status" />
      </template>
      <template #cell(attachments)="{item}">
        <span
          v-for="(data, index) in item.attachments"
          :key="index"
        >
          <a
            :href="data.file"
            target="_blank"
          >
            <span>View File {{ index + 1 }} </span>
            <span v-if="index != item.attachments.length - 1">, </span>
          </a>
        </span>
      </template>
      <template #cell(statement_id)="{item}">
        <span> {{ item.statement_id || '-' }} </span>
      </template>
      <template #cell(actions)="{item}">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Attach Statement"
          icon="EditIcon"
          class="cursor-pointer"
          size="18"
          @click="handleAttachClick(item)"
        />

      </template>
    </b-table>
    <detailed-pagination
      v-if="!loading && !errorMessage"
      :total-items="totalItems"
      :per-page="filters.perPage"
      :current-page="filters.page"
      @page-changed="(page) => {filters.page = page}"
    />
    <attach-statement
      v-if="attachData"
      v-model="dialog"
      :data="attachData"
      @handleUpdate="handleUpdate"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  BTable, BSpinner, BAlert, VBTooltip,
} from 'bootstrap-vue'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import getParsedQueryString from '@/utils/headerQueryParser'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import AttachStatement from '@/components/CreditDebitNotes/AttachStatement.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable, DetailedPagination, BSpinner, BAlert, StatusIcon, AttachStatement,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    value: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      notesData: [],
      tableFields: [
        { key: 'doc_no', label: 'Doc No.', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'party__identifier', label: 'Party', sortable: true },
        {
          key: 'amount', label: 'Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'mode', label: 'Mode', sortable: true },
        { key: 'pay_status', label: 'Payment Status', sortable: true },
        {
          key: 'due_amount', label: 'Due Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'attachments', label: 'Attachments', sortable: false },
        { key: 'statement_id', label: 'Statement Id', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      totalItems: 0,
      dialog: false,
      attachData: null,
    }
  },
  computed: {
    filters: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchListingData()
      },
      deep: true,
    },
    dialog(newVal) {
      if (!newVal) {
        this.attachData = null
      }
    },
  },
  mounted() {
    this.fetchListingData()
  },
  methods: {
    sortingChanged(ctx) {
      this.filters.sortBy = ctx.sortBy
      this.filters.sortDesc = ctx.sortDesc
    },
    async fetchListingData() {
      try {
        const {
          docNo,
          date,
          type,
          party,
          mode,
          payStatus,
          sortBy,
          sortDesc,
          page,
          perPage,
        } = this.filters
        this.loading = true

        const [fromDate, toDate] = date?.split(' to ') || ''

        const preparedPayload = {
          doc_no: docNo,
          ...(fromDate && toDate
            ? { from_date: fromDate, to_date: toDate }
            : {}),
          type,
          party,
          mode,
          pay_status: payStatus,
          sort_by: sortBy,
          sort_desc: sortDesc,
          page,
          per_page: perPage,
        }

        const response = await axios.get(
          `/accounts/credit_debit_notes?${getParsedQueryString(preparedPayload)}`,
        )
        const { results, count } = response.data
        this.notesData = results
        this.totalItems = count
      } catch (error) {
        this.errorMessage = error?.response?.data?.details || 'Failed to fetch credit/debit notes.'
      } finally {
        this.loading = false
      }
    },
    handleAttachClick(data) {
      this.attachData = data
      this.dialog = true
    },
    handleUpdate() {
      this.dialog = false
      this.fetchListingData()
    },
  },
}
</script>
