var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Small Spinner","variant":"primary"}})],1):_vm._e(),_c('b-alert',{attrs:{"variant":"danger","show":_vm.errorMessage ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])]),(!(_vm.loading || _vm.errorMessage))?_c('b-table',{staticClass:"compact-table",attrs:{"items":_vm.notesData,"fields":_vm.tableFields,"responsive":"","striped":"","hover":"","show-empty":"","no-local-sorting":"","sort-by":_vm.filters.sortBy,"sort-desc":_vm.filters.sortDesc},on:{"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"cell(doc_no)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(item.doc_no)+" "),(item.is_modified)?_c('span',{staticClass:"is-modified-bill"}):_vm._e()])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.date))+" ")]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateNumber(item.amount))+" ")]}},{key:"cell(due_amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateNumber(item.due_amount))+" ")]}},{key:"cell(pay_status)",fn:function(ref){
var item = ref.item;
return [_c('status-icon',{attrs:{"value":item.pay_status}})]}},{key:"cell(attachments)",fn:function(ref){
var item = ref.item;
return _vm._l((item.attachments),function(data,index){return _c('span',{key:index},[_c('a',{attrs:{"href":data.file,"target":"_blank"}},[_c('span',[_vm._v("View File "+_vm._s(index + 1)+" ")]),(index != item.attachments.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])])})}},{key:"cell(statement_id)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.statement_id || '-')+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({boundary:'window'}),expression:"{boundary:'window'}",modifiers:{"hover":true}}],staticClass:"cursor-pointer",attrs:{"title":"Attach Statement","icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.handleAttachClick(item)}}})]}}],null,false,2822735918)}):_vm._e(),(!_vm.loading && !_vm.errorMessage)?_c('detailed-pagination',{attrs:{"total-items":_vm.totalItems,"per-page":_vm.filters.perPage,"current-page":_vm.filters.page},on:{"page-changed":function (page) {_vm.filters.page = page}}}):_vm._e(),(_vm.attachData)?_c('attach-statement',{attrs:{"data":_vm.attachData},on:{"handleUpdate":_vm.handleUpdate},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }