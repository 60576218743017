<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Document No.">
        <b-form-input
          v-model="docNumber"
          type="text"
          placeholder="Search Document Number"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Date">
        <FlatPicker
          v-model="filters.date"
          placeholder="Select Date Range"
          :config="{ mode: 'range' }"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Type">
        <custom-v-select
          v-model="filters.type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="typeOptions"
          placeholder="Select Type"
          :deselect-from-dropdown="true"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Party">
        <custom-v-select
          v-model="filters.party"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="buyerOptions"
          label="text"
          placeholder="Select buyer"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          multiple
          :reduce="(option) => option.id"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Mode">
        <custom-v-select
          v-model="filters.mode"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="modeOptions"
          placeholder="Select Mode"
          :deselect-from-dropdown="true"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="3"
      lg="2"
    >
      <b-form-group label="Payment Status">
        <custom-v-select
          v-model="filters.payStatus"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="paymentStatusOptions"
          placeholder="Select Payment Status"
          :deselect-from-dropdown="true"
          @input="handleFilterChange"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    FlatPicker,
    CustomVSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      typeOptions: [
        'CreditNote-SR',
        'CreditNote-BRK/EXP',
        'CreditNote-OTHER',
        'DebitNote-OTHER',
      ],
      buyerOptions: [],
      modeOptions: ['CREDIT', 'CASH'],
      paymentStatusOptions: ['Paid', 'Not Paid', 'Partially Paid'],
    }
  },

  computed: {
    filters() {
      return this.$props.value
    },
    docNumber: {
      get() {
        return this.filters.docNo
      },
      set(newVal) {
        this.updateFilter({ docNo: newVal })
      },
    },
  },
  mounted() {
    this.fetchBuyerOptions()
  },
  methods: {
    updateFilter: debounce(function updateFilter(newFilter) {
      this.$emit('input', {
        ...this.filters,
        ...newFilter,
        page: 1,
      })
    }, 500),
    handleFilterChange() {
      this.$emit('input', {
        ...this.filters,
        page: 1,
      })
    },
    async fetchBuyerOptions() {
      try {
        const { data } = await axios.get('/accounts/buyers_options')
        this.buyerOptions = data.data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message || 'Something went wrong!',
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
