<template>
  <b-card
    title="Credit/Debit Notes"
  >
    <credit-debit-filters v-model="filters" />
    <credit-debit-listing v-model="filters" />
  </b-card>

</template>

<script>
import CreditDebitFilters from '@/components/CreditDebitNotes/CreditDebitFilters.vue'
import CreditDebitListing from '@/components/CreditDebitNotes/CreditDebitListing.vue'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    CreditDebitFilters,
    CreditDebitListing,
    BCard,

  },
  data() {
    return {
      filters: {
        docNo: null,
        date: null,
        type: null,
        party: null,
        mode: null,
        payStatus: null,
        sortBy: 'date',
        sortDesc: true,
        page: 1,
        perPage: 50,
      },
    }
  },

}
</script>
